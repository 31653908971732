body {
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: #000000;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif
}

p {
    font-size: 18px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 0 16px 16px 16px;
}

.container {
    width: 95%;
    max-width: 900px;
    margin: 20px auto;
    text-align: left;
    font-size: 16px;
    color: #000000;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif
}

.container h3.policy {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0 0 2px 0;
    color: #000000;
}

.container h1 {
    color: black
}

.container h3 {
    margin: 0 0 2px 0;
    color: #646464;
}

.container h4 {
    margin: 0
}

.container p a {
    color: #646464
}

.container ul {
    padding: 0 0 0 30px;
    margin: 10px 0;
}

.container footer {
    background: #f9f9f9;
    border: solid #e5e5e5;
    border-width: 1px 0;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
}


.comment_text {
    margin-top: 5px;
    margin-left: 5px;
    width: 360px;
    height: 100px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none repeat scroll 0 0 #FFFFFF;
    border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
    border-image: none;
    border-radius: 6px 6px 6px 6px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #555555;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    line-height: 1.4em;
    padding: 5px 8px;
    transition: background-color 0.2s ease 0s;
    resize: none;
    white-space: pre;
}

#shellPrimaryNoPadding {
    height: auto;
    margin: 20px auto;
    position: relative;
    width: auto;
    background: #eeeeee;
    border-radius: 16px 16px 16px 16px;
    padding: 16px;
    overflow: hidden;
    box-shadow: 5px 5px 5px #CCC;
}

.containerNoMargin {
    width: 95%;
    max-width: 900px;
    margin: 4px auto;
    text-align: left;
    font-size: 16px;
    color: #ffffff;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif
}