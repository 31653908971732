#shellPrimaryNoPadding {
    height: auto;
    margin: 20px auto;
    position: relative;
    width: auto;
    background: #eeeeee;
    border-radius: 16px 16px 16px 16px;
    padding: 16px;
    overflow: hidden;
    box-shadow: 5px 5px 5px #CCC;
}

.containerNoMargin {
    width: 95%;
    max-width: 900px;
    margin: 4px auto;
    text-align: left;
    font-size: 16px;
    /*color: #ffffff;*/
    /*font-family: 'Open Sans', 'Helvetica Neue', sans-serif*/
}

div.top_menu {
    text-align: center;
    font-size: 17px;
    overflow: hidden;
}

div.top_menu li {
    border-left: 1px solid #000000;
    display: inline;
    padding: 0 8px;
}

div.top_menu li {
    border: none;
    text-decoration: underline;
}

div.top_menu li.current {
    border: none;
    font-weight:  bolder;
    text-decoration: none;
}

div.top_menu li.current a {
    border: none;
    font-weight:  bold;
    text-decoration: none;
}

div.top_menu a {
    color: #000000;
    text-decoration: none;
    font-size: large;
}

/*div.top_menu a:hover {*/
/*    text-decoration: underline;*/
/*}*/