body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #000000;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif
}

.span{
    color: #dc143c;
}

.container {
    width: 95%;
    max-width: 900px;
    margin: 20px auto
}

.container h1 {
    color: black
}

.container h3 {
    margin: 0 0 2px 0;
    color: #646464;
}

.container h4 {
    margin: 0
}

.container p a {
    color: #646464
}

.container ul {
    padding: 0 0 0 30px;
    margin: 10px 0;
}

.container footer {
    background: #f9f9f9;
    border: solid #e5e5e5;
    border-width: 1px 0;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.container img.phone {
    height: 50%;
    width: 50%;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: left;
}

.container img.tv {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

#shell1 {
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#FCF690;
    border-radius: 16px 16px 16px 16px;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px #CCC;
}

#shell2 {
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#adf4ff;
    border-radius: 16px 16px 16px 16px;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px #CCC;
}

#shell3 {
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#b2ffa1;
    border-radius: 16px 16px 16px 16px;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px #CCC;
}

#shell4 {
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#eeeeee;
    border-radius: 16px 16px 16px 16px;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px #CCC;
}

#shell5 {
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#ffc7c7;
    border-radius: 16px 16px 16px 16px;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px #CCC;
}

#shell6 {
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#b6caff;
    border-radius: 16px 16px 16px 16px;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px #CCC;
}

#title {
    background:#FCF690;
    padding:16px;
}

.downloadTableTop {
    transition: box-shadow .2s;
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#adf4ff;
    border-radius: 16px 16px 0px 0px;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px 5px #CCC;
}
.downloadTableTop:hover {
    box-shadow: 0 0 0 #CCC;
}

.downloadTableMiddle {
    transition: box-shadow .2s;
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#adf4ff;
    border-radius: 0px 0px 0px 0px;
    border-top: 1px solid #646464;
    border-bottom: 1px solid #646464;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px 5px #CCC;
}
.downloadTableMiddle:hover {
    box-shadow: 0 0 0 0 #CCC;
}

.downloadTableBottom {
    transition: box-shadow .2s;
    height: auto;
    margin: 0 auto;
    position: relative;

    width:auto;
    background:#adf4ff;
    border-radius: 0px 0px 16px 16px;
    padding:16px;
    overflow:hidden;
    box-shadow: 5px 5px 5px 5px #CCC;
}
.downloadTableBottom:hover {
    box-shadow: 0 0 0 #CCC;
}

div.hgap {
    float: left;
    overflow: hidden;
    height: 1px;
    width: 0px;
}
