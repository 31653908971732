#shellPrimaryNoPadding {
    height: auto;
    margin: 20px auto;
    position: relative;
    width: auto;
    background: #eeeeee;
    border-radius: 16px 16px 16px 16px;
    padding: 16px;
    overflow: hidden;
    box-shadow: 5px 5px 5px #CCC;
}

.containerNoMargin {
    width: 95%;
    max-width: 900px;
    margin: 4px auto;
    text-align: left;
    font-size: 16px;
    color: #ffffff;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif
}

.container footer {
    background: #f9f9f9;
    border: solid #e5e5e5;
    border-width: 1px 0;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
}

div.fast_nav {
    text-align: center;
    font-size: 11px;
    overflow: hidden;
}

div.fast_nav li.first {
    border: none;
}

div.fast_nav a:hover {
    text-decoration: underline;
}

#shellPrimaryNoPadding {
    height: auto;
    margin: 20px auto;
    position: relative;
    width: auto;
    background: #eeeeee;
    border-radius: 16px 16px 16px 16px;
    padding: 16px;
    overflow: hidden;
    box-shadow: 5px 5px 5px #CCC;
}

.containerNoMargin {
    width: 95%;
    max-width: 900px;
    margin: 4px auto;
    text-align: left;
    font-size: 16px;
    color: #ffffff;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif
}

div.fast_nav {
    text-align: center;
    font-size: 11px;
    overflow: hidden;
    width: 100%;
}

div.fast_nav li {
    border-left: 1px solid #000000;
    display: inline;
    padding: 0 7px;
    text-decoration: underline;
}

div.fast_nav li.current a {
    /*border: none;*/
    font-weight:  bolder;
    text-decoration: none;
    /*normal | bold | | lighter | number | initial | inherit;*/
}

div.fast_nav li.current {
    /*border: none;*/
    font-weight:  bolder;
    text-decoration: none;
    /*normal | bold | | lighter | number | initial | inherit;*/
}

div.fast_nav li.first a {
    border: none;
    font-weight:  bolder;
    text-decoration: none;
    /*normal | bold | | lighter | number | initial | inherit;*/
}

/*div.fast_nav a {*/
/*    color: #000000;*/
/*    text-decoration: none;*/
/*}*/
